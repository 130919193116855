import React, { useEffect, useRef} from "react"
import { FormattedMessage } from "react-intl"

//importing components
import SEO from "../components/seo"
import BioItem from "../components/BioItem"
import ContactScreen from "../components/Contact"
import HomeScreen from "../components/Home"

import Layout from "../components/layout/it"  // here language in IT

//import styledComponents
import {
  StyledLink,
  LinkContainer,
  Padded,
  TopBar,
  WhoAmI,
  SectionTitle,
} from "../components/StyledHome"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import WorkItem from "../components/WorkItem"
import CookieConsent from "react-cookie-consent"
import DoubleItem from "../components/DoubleItem"

const IndexPage = (props) => {
  const item = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }

    gsap.from(item.current, {
      autoAlpha: 0,
      scale: 0.7,
      scrollTrigger: {
        trigger: item.current,
        start: "top bottom",
        end: "top 150",
        markers: false,
        scrub: true,
        anticipatePin: 1,
      },
    });
  });

  return (
    <Layout location={props.location} >
      <SEO title={"Home"} lang={'it'} />
      <TopBar>
        <LinkContainer>
          <StyledLink to="/it/#bio">BIO</StyledLink>
          <StyledLink to="/it/#work">LAVORO</StyledLink>
          <StyledLink to="/it/#contact">CONTATTI</StyledLink>
        </LinkContainer>
      </TopBar>
      <HomeScreen />
      <WhoAmI ref={item}>
        <Padded>
          <FormattedMessage id="app.whoami" values={{ br: <br /> }} />
        </Padded>
      </WhoAmI>

      <SectionTitle id="bio">BIO</SectionTitle>

      <BioItem
        position={"left"}
        img={"io_toga_comp.jpg"}
        text={<FormattedMessage id="bio.bachelor" values={{ br: <br /> }} />}
      />
      <BioItem
        position={"right"}
        img={"io_fablab.jpg"}
        text={<FormattedMessage id="bio.fablab" values={{ br: <br /> }} />}
      />
      <BioItem
        position={"left"}
        img={"jemp_group.jpg"}
        text={<FormattedMessage id="bio.jemp" values={{ br: <br /> }} />}
      />
      <BioItem
        position={"right"}
        img={"iousls_big.jpeg"}
        text={<FormattedMessage id="bio.usls" values={{ br: <br /> }} />}
      />
      <BioItem
        position={"left"}
        img={"usa_comp.jpg"}
        text={<FormattedMessage id="bio.usa" values={{ br: <br /> }} />}
      />

      <SectionTitle id="work">LAVORO</SectionTitle>

      <WorkItem
        img={"code_react_cut.png"}
        text={<FormattedMessage id="work.react" values={{ br: <br /> }} />}
      />

      <SectionTitle id="cycling">CYCLING</SectionTitle>
      <DoubleItem
        position={"left"}
        img={"podium.jpg"}
        img2={"santiago.jpg"}
        text={<FormattedMessage id="cycling" values={{ br: <br /> }} />}
      />

      <SectionTitle id="contact">CONTATTI</SectionTitle>
      <ContactScreen />
    </Layout>
  )
}

export default IndexPage
