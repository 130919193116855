import React from 'react';
import Layout from "./layout"


//here import specific things form italian lang
import messages from '../../intl/it.json';

export default (props) => (
  <Layout
    {...props}
    messages={messages}
  />
);
